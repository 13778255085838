import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Model, StylesManager } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";

import { ReactElementFactory } from "survey-react-ui";

import axios from 'axios';
import {axiosNodeClient} from '../../variables';

import {API_URL} from "../../variables";
import { FRONTEND_URL } from "../../variables";

import { buildConsultationJson } from "../../data/json-forms/Consultation_Form";
import { branchCategoryMappings } from "../../data/category-mappings/branch_categories"
import { concernCategoryMappings } from "../../data/category-mappings/concern_categories"

let concernsTextGlobal = [];

// import { surveyTheme } from '../../data/survey_theme';
// StylesManager.applyTheme(surveyTheme);

function Consultation({ skinGoalsDesc }) {
  const isExistingClient = useRef(false);
  const clientObj = useRef(null);
  const phorestClientObj = useRef(null);
  const isExistingClientPhorest = useRef(false);
  const clientSessionId = useRef(null);
  const redirect = useNavigate();
  const [clientNamesArr, setClientNamesArr] = useState([])
  const branch_id = useRef(null);
  const [formComplete, setFormComplete] = useState(false)
  const [extractEmailConsent, setExtractEmailConsent] = useState(false)
  const [emailConsented, setEmailConsented] = useState(false)
  const [clientSubmissionData, setClientSubmissionData] = useState({})
  const [loading, setLoading] = useState(false);
  const [submissionState, setSubmissionState] = useState('initial');

  useEffect( () => {
    const pstObj = JSON.parse(localStorage.getItem('fmpfp_therapist_obj'));

    if (pstObj) {
      branch_id.current = pstObj.pb_i;
    }
    
    const getClientNames = async () => {
      try {
        // const res = await axiosClient.get(`/api/collections/sessions/records?filter=(phorest_branch_id='${phorest_branch_id}')`);
        const res = await axiosNodeClient.post('/retrieve-client-names', 
          JSON.stringify({pb_i: branch_id.current}),
          {
            headers: {
                'content-type': 'application/json'
            }
          }
        );

        const clientNameArr = JSON.parse(res.data.data);
        setClientNamesArr([...new Set(clientNameArr)]) // remove duplicates
      } catch(err) {
        //console.log(err.message)
      }
    }

    getClientNames();
  }, [])

  const model = new Model(buildConsultationJson({
    client_names: clientNamesArr
  }));

  const savedFormData = localStorage.getItem('sv_cfd');

  if (savedFormData) {
    model.data = JSON.parse(savedFormData);
  }

  model.onValueChanged.add((survey, options) => {
    const surveyDataObj = survey.data;

    delete surveyDataObj["first_name"];
    delete surveyDataObj["last_name"];
    delete surveyDataObj["email"];
    delete surveyDataObj["clientlookup"];

    localStorage.setItem('sv_cfd', JSON.stringify(surveyDataObj));
  })

  // finish here after refactoring api calls
  const surveyComplete = async (sender) => {
    try {
      setFormComplete(true);
      let email, first_name, last_name, birthdate, mobileNumber;

      if (sender.data['clientlookup']?.length > 0) {
        let clientNameArr = sender.data['clientlookup'].split(' - ').map(value => {
          return value.trim();
        });

        email = clientNameArr[clientNameArr.length - 1];
        first_name = clientNameArr[0].split(" ")[0];
        last_name = clientNameArr[0].split(" ")[1];
      } else {
        email = sender.data['email'].trim();
        first_name = sender.data['first_name'].trim();
        last_name = sender.data['last_name'].trim();
      }

      birthdate = sender.data.hasOwnProperty("birthdate") ? sender.data.birthdate : null;
      mobileNumber = sender.data.hasOwnProperty("mobile") ? sender.data.mobile : null;

      const therapistObj = JSON.parse(localStorage.getItem('fmpfp_therapist_obj'));
      // axiosNodeClient.defaults.headers['authorization'] = localStorage.getItem('auth_code');

      // call get all clients from phorest and check
      let phorestClientRes = await axios.get(`${API_URL}/get-phorest-clients?email=${encodeURIComponent(email)}`);
      phorestClientRes = JSON.parse(phorestClientRes.data.data);

      isExistingClientPhorest.current = phorestClientRes.is_existing_client;

      if (isExistingClientPhorest.current) {
        phorestClientObj.current = phorestClientRes.client_obj;
      }

      // phorestClientRes.forEach(client => {
      //   if (client.email === email) {
      //     isExistingClientPhorest.current = true;
      //     phorestClientObj.current = client;
      //   } 
      // })

      // get all clients from backend and check
      let clientRes = await axiosNodeClient.post(`/retrieve-backend-clients?email=${encodeURIComponent(email)}`, 
        JSON.stringify({pb_i: branch_id.current}),
        {
          headers: {
            'content-type': 'application/json'
          }
        }
      );

      clientRes = JSON.parse(clientRes.data.data);
      isExistingClient.current = clientRes.is_existing_client;

      if (isExistingClient.current) {
        clientObj.current = clientRes.client_obj;
      }

      // clientRes.forEach(client => {
      //   if (client.email === email) {
      //     isExistingClient.current = true;
      //     clientObj.current = client;
      //   }
      // })

        //write session and insert into phorest client notes
      if (!isExistingClient.current) {

        // post client to backend 
        const createClientPayload = {
          "first_name": first_name,
          "last_name": last_name,
          "email": email,
        }

        if (sender.data["consent-data-sharing"].length > 0) {
          createClientPayload["data_consent"] = true;
        }

        if (Object.keys(sender.data).includes("consent-emails")) {
          sender.data["consent-emails"].forEach(consent => {
            if (consent === "advice_consent") {
              createClientPayload["prescription_email_consent"] = true;
            } else if(consent === "subscribe_consent") {
              createClientPayload["promo_consent"] = true;
            }
          })
        }

        const createClientRes = await axiosNodeClient.post(`/create-backend-client?pb_i=${branch_id.current}`, createClientPayload);
        clientObj.current = JSON.parse(createClientRes.data.data);
      } 

      if (!isExistingClientPhorest.current) {
        // post to express route and write to phorest api

        const createClientPayload = {
          "firstName": first_name,
          "lastName": last_name,
          "email": email,
          "birthDate": birthdate,
          // "mobile": mobileNumber
        }

        if (Object.keys(sender.data).includes("consent-emails")) {
          sender.data["consent-emails"].forEach(consent => {
            if (consent === "subscribe_consent") {
              createClientPayload["emailMarketingConsent"] = true;
              createClientPayload["emailReminderConsent"] = true;          
            } 
          })
        }

        const postClientRes = await axios.post(`${API_URL}/create-phorest-client`, createClientPayload)
        phorestClientObj.current = JSON.parse(postClientRes.data.data)

        // for stagin -- phorestClientObj.current = JSON.parse(postClientRes.data)
      }
      
      sender.data["skin_goals_desc"] = skinGoalsDesc;

      // send skin fitness img to s3
      let skin_fitness_url;

      try {

        if (localStorage.getItem("skin_goals_img")) {
          const upload_skin_fitness_to_s3 = await axiosNodeClient.post(`/upload-image-to-bucket`, {
            folder: "skin-fitness",
            img_string: localStorage.getItem("skin_goals_img")
          });
  
          skin_fitness_url = upload_skin_fitness_to_s3.data.img;
          sender.data["skin_goals_img"] = skin_fitness_url;
        }      
      } catch(err) {
        // handle error
        //console.log(err.message)
      }

      // send signature img to s3
      let signature_url;
      let form_data_obj = {...sender.data}

      try {
        const upload_sig_to_s3 = await axiosNodeClient.post('/upload-image-to-bucket', {
          folder: "signature",
          img_string: sender.data['signature']
        });

        signature_url = upload_sig_to_s3.data.img;
        form_data_obj['signature'] = signature_url;

      } catch (err) {
        //console.log(err.message)
      }

      try {
        if (!form_data_obj.hasOwnProperty("email")) {
          form_data_obj["email"] = email;
          form_data_obj["first_name"] = first_name;
          form_data_obj["last_name"] = last_name;
        }
      } catch {
        console.error("Error setting fields in form_data_obj")
      }

      //console.log(sender.data)

      // POST session to backend
      const payload = {
        "data": {
          "skin_goals": {
            "skin_goals_desc": skinGoalsDesc,
            "skin_goals_img": skin_fitness_url
          },
          "form_data": form_data_obj
        },
        "client_id": clientObj.current.id,
        "phorest_staff_id": therapistObj.ps_i,
        "phorest_branch_id": therapistObj.pb_i,
        "phorest_client_id": phorestClientObj.current.clientId,
      }

      // console.log(payload.data.form_data['consent-data-sharing'])

      try {
         // * handle email advice consent
        if (payload.data.form_data['consent-emails'].includes('advice_consent')){
          setEmailConsented(true)
          setExtractEmailConsent(true)
          // console.log(emailConsented)
        } else {
          setExtractEmailConsent(true);
        }
      } catch {
        console.log('setting email extracted')
        setExtractEmailConsent(true)
      }

      if (sender.data.hasOwnProperty("consent-emails")) {
        // check if connect_customer is ticked, and push business ID if it does not exist in clientCategoryIds
        if (sender.data["consent-emails"].includes("connect_customer")
          && !phorestClientObj.current.clientCategoryIds.includes("Gg027D4oUaewckbTPFqJRw")
        ) {
          phorestClientObj.current.clientCategoryIds.push("Gg027D4oUaewckbTPFqJRw")
        }

        if (sender.data["consent-emails"].includes("customer_survey")
          && !phorestClientObj.current.clientCategoryIds.includes("opZojBBwenjjpTRAkph6zQ")
        ) {
          phorestClientObj.current.clientCategoryIds.push("opZojBBwenjjpTRAkph6zQ")
        }
      }

      // pull branch category id from mapping obj
      const b_n = localStorage.getItem("b_n")
      const branchCategoryId = branchCategoryMappings[b_n]["clientcategoryid"];
      const allBranchCategoryIds = Object.values(branchCategoryMappings).map(obj => obj.clientcategoryid);
      
      if (!phorestClientObj.current.clientCategoryIds.includes(branchCategoryId)) {
        phorestClientObj.current.clientCategoryIds.push(branchCategoryId);
      }

      // filter out any existing category ids
      const allConcernIds = Object.keys(concernCategoryMappings).map(obj => obj.categoryId);
      let newCategoryIdsArr = phorestClientObj.current.clientCategoryIds.filter(id => 
        allConcernIds.indexOf(id) != -1 ||
        allBranchCategoryIds.includes(id) || 
        id === "Gg027D4oUaewckbTPFqJRw" ||
        id === "opZojBBwenjjpTRAkph6zQ"
      );

      let concernsMappings;

      try {
        concernsMappings = JSON.parse(localStorage.getItem('skin_icons_text_arr'));
        concernsTextGlobal = concernsMappings; // set global variable
      } catch {
        concernsMappings = null;
      }
      
      if (concernsMappings) {
        concernsMappings.forEach((key) => {
          const concernId = concernCategoryMappings[key]["categoryId"];
          newCategoryIdsArr.push(concernId);
        })
      }

      newCategoryIdsArr = Array.from(new Set(newCategoryIdsArr));

      phorestClientObj.current.clientCategoryIds = newCategoryIdsArr;
      payload.data["client_category_ids"] = newCategoryIdsArr;

      //console.log(payload)

      const sessionRes = await axiosNodeClient.post(`/create-backend-session?pb_i=${branch_id.current}`, payload);
      clientSessionId.current = JSON.parse(sessionRes.data.data).id;

      // post to phorest client notes our backend session response
      const clientNotes = "";

      if (phorestClientObj.current.notes) {
        phorestClientObj.current.notes = phorestClientObj.current.notes += ` ${FRONTEND_URL}/consultation-session/${clientSessionId.current}`;
      } else {
        phorestClientObj.current.notes = `${FRONTEND_URL}/consultation-session/${clientSessionId.current}`;
      }

      const updateClientNotesRes = await axios.post(`${API_URL}/update-phorest-client-notes`, phorestClientObj.current)
      
      setClientSubmissionData({
        ...payload.data,
        pst_name: localStorage.getItem('fmpfp_therapist_obj').fn,
        salon_name: localStorage.getItem('b_n')
      })

      localStorage.removeItem('sv_cfd') // clear form data
      localStorage.removeItem("skin_icons") // clear skin icons
      localStorage.removeItem("skin_goals_desc") // clear skin goals desc
      localStorage.removeItem("skin_icons_text_arr")
    } catch(err) {
      console.log(err)
      localStorage.removeItem('fmpfp_therapist_obj');
      localStorage.removeItem('auth_code');
      localStorage.removeItem("skin_icons_text_arr")

      localStorage.removeItem('sv_cfd') // clear form data
      localStorage.removeItem("skin_icons") // clear skin icons
      localStorage.removeItem("skin_goals_desc") // clear skin goals desc
      //console.log(err)
      redirect('/');
    }
  }

  model.onComplete.add(surveyComplete);

  async function handleSendgridEmail(e) {
    try {
      e.target.children[0].innerHTML = "email sent";
      e.target.classList.add("fmpfp_consultation_email_btn_disabled");
  
      // Function to check and wait for clientSubmissionData to be populated
      const waitForData = () => new Promise((resolve, reject) => {
        const checkData = setInterval(() => {
          if (Object.keys(clientSubmissionData).length > 0) {
            clearInterval(checkData);
            resolve(clientSubmissionData);
          }
        }, 500);
        // Optionally, add a timeout to reject the promise if it takes too long
        setTimeout(() => {
          clearInterval(checkData);
          reject("Timeout waiting for clientSubmissionData");
        }, 10000); // 10 seconds timeout
      });
  
      if (Object.keys(clientSubmissionData).length === 0) {
        // Wait for clientSubmissionData to be populated
        await waitForData();
      }

      const payload = {...clientSubmissionData};

      if (clientSubmissionData.form_data.hasOwnProperty("service")) {
        const serviceName = clientSubmissionData.form_data.service[0]["service-select"];
        const surveyJson = buildConsultationJson();
        let serviceDesc;

        surveyJson.pages[0].elements[3].templateElements.forEach(el => {
          if (el.visibleIf?.includes(serviceName) && el.type === 'html') {
            serviceDesc = el.html.slice(el.html.indexOf("</span></h2>\n\n") + 14);
            serviceDesc = serviceDesc.replace(/<\/?div>/gi, '').replace(/\n/g, '');
          }
        })
        payload.treatment_desc = serviceDesc;
      }
      
      payload.skin_concerns = concernsTextGlobal;

      // At this point, clientSubmissionData is guaranteed to be populated
      const res = await axios.post(`${API_URL}/handle-sendgrid-email`, payload);
    } catch(err) {
      console.error("Error sending email through SendGrid:", err);
      // Consider adding more robust error handling here
    }
  }


  useEffect(() => {
    let checkDataInterval;
    setLoading(true);
    setSubmissionState('loading');

    if (formComplete && emailConsented && extractEmailConsent) {
      checkDataInterval = setInterval(() => {
        if (Object.keys(clientSubmissionData).length > 0) {
          clearInterval(checkDataInterval);
          setSubmissionState('dataReady');
          setLoading(false);
        }
      }, 500);

      // // Timeout to stop waiting and proceed with the submission text if data loading takes too long
      // setTimeout(() => {
      //   if (submissionState !== 'dataReady') {
      //     clearInterval(checkDataInterval);
      //     setSubmissionState('submitted');
      //     setLoading(false);
      //   }
      // }, 10000); // 10 seconds timeout
    } else if (formComplete && !emailConsented && extractEmailConsent) {
      setSubmissionState('submitted');
      setLoading(false);
    }

    // Cleanup function to clear the interval when the component unmounts or the dependencies change.
    return () => {
      if (formComplete && emailConsented && extractEmailConsent) {
        clearInterval(checkDataInterval);
      }
    };
  }, [formComplete, emailConsented, extractEmailConsent, clientSubmissionData]);

  function renderContent() {
    const sendSendgridBtn = 
    <div className="fmpfp_consultation_email_btn" onClick={(e) => handleSendgridEmail(e)}>
      <p className="fmpfp_consultation_email_btn_text">
        email form results
      </p>
    </div>

  const form_submitted_text = 
    <p className="fmpfp_consultation_complete_text">
      Thank you for completing the consultation form.
    </p>

  const loadingIcon = 
    <div className="lds-roller fmpfp_consultation_roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>

    if (!formComplete) {
      return <Survey model={model} />;
    }

    if (formComplete) {
      switch (submissionState) {
        case 'loading':
          return loadingIcon;
        case 'dataReady':
          return [form_submitted_text, sendSendgridBtn];
        case 'submitted':
        default:
          return form_submitted_text;
      }
    }
  }

  return (
    <div id="fmpfp_consultation_container" className="fmpfp_form_component">
      {renderContent()}
      {/* {!formComplete ? <Survey model={model} /> : null}
      {formComplete && !emailConsented ? form_submitted_text : null}
    {formComplete && emailConsented ? sendSendgridBtn : null} */}
    </div>
  );
}

export default Consultation;
